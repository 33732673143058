export const API = {
  AUTH: {
    LOGIN: "api/v1/auth/authenticate",
    REGISTER: "api/v1/auth/register",
  },
  USER: {
    PROFILE: "api/v1/users/self",
    PHOTO: "api/v1/users/self/photo",
    PASSWORD: "api/v1/users/self/password",
    RESUME: "api/v1/users/self/resume",
  },
  MANAGER: {
    OFFER: "api/v1/management/offer",
    OFFER_PHOTO: "api/v1/management/offer/photo",
    USERS: "api/v1/management/users",
    USER_RESUME: (id: string) => `api/v1/management/users/${id}/resume`,
    CATEGORY: "api/v1/management/category",
    REVIEWS: "api/v1/management/reviews",
    PARTNER: "api/v1/management/partner",
    PARTNER_PHOTO: (id: string) => `api/v1/management/partner/${id}/image`,
    VERIFY_USER: (id: string) => `api/v1/management/users/${id}/verify`,
    SUBMISSIONS: (offerId: string) =>
      `api/v1/management/submissions/${offerId}`,
  },
  ADMIN: {
    ROLE_MANAGER: (email: string) => `api/v1/admin/manager/${email}`,
  },
  CATEGORIES: "api/v1/categories",
  OFFERS: "api/v1/offers",
  SUBMIT_OFFER: (offerId: string) => `api/v1/offers/${offerId}/submit`,
  REVIEWS: "api/v1/reviews",
  PARTNERS: "api/v1/partners",
};
